@font-face {
    font-family: 'Karla';
    src: url('fonts/en/Karla-Regular.ttf');
}

@font-face {
    font-family: 'Alexandria';
    src: url('fonts/ar/Alexandria-SemiBold.ttf');
    font-display: swap;
    font-weight: 700;
}

@font-face {
    font-family: 'Alexandria';
    src: url('fonts/ar/Alexandria-Medium.ttf');
    font-display: swap;
    font-weight: 500;
}

@font-face {
    font-family: 'Alexandria';
    src: url('fonts/ar/Alexandria-Regular.ttf');
    font-display: swap;
    font-weight: 400;
}

html[lang*=en] body{
    font-family: Karla !important;
}

html[lang*=ar] body{
    font-family: Alexandria !important;
}

.MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-iconButtonContainer{
    display: none !important;
}

.MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-menuIcon{
    display: none !important;
}

.MuiTableHead-root .MuiTableCell-head{
    font-weight: bold;
}

.muirtl-204u17-MuiDataGrid-main{
    min-height: 100px;
}

.order-bill-block .muiltr-9lyone-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled, .order-bill-block .muirtl-9lyone-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled, .muiltr-zikw4a-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled, .muirtl-zikw4a-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
    -webkit-text-fill-color: unset !important;
}

.muiltr-11y35xg-MuiButtonBase-root-MuiRadio-root.Mui-disabled, .muirtl-11y35xg-MuiButtonBase-root-MuiRadio-root.Mui-disabled, .muiltr-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled, .muirtl-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled, .muiltr-1f2kvjf-MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled, .muirtl-1f2kvjf-MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled{
    color: unset !important;
}

.muiltr-8skbml-MuiInputBase-root-MuiOutlinedInput-root {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4375em;
    font-family: Alexandria;
    color: #364152;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    position: relative;
    border-radius: 4px;
    /*padding-left: 14px;*/
    background: #f8fafc;
    border-radius: 12px;
}

.muiltr-9lyone-MuiInputBase-input-MuiOutlinedInput-input{
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    color: #121926;
    padding: 16.5px 14px;
    padding-left: 0;
    font-weight: 500;
    background: #f8fafc;
    padding: 15.5px 14px;
    border-radius: 12px;
}

.muiltr-wb57ya-MuiFormControl-root-MuiTextField-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    vertical-align: top;
    width: 100%;
}

/*.muiltr-eq27l2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected:hover{*/
/*    background-color: #0e3152 !important;*/
/*    color: #fff*/
/*}*/